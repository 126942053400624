export default {
  agreementText: "I agree to",
  alreadyHaveAnAccount: "Already have an account?",
  and: "and",
  businessName: "Business Name",
  businessNameIsRequired: "Business name is required",
  businessPhoneNumber: "Business Phone Number",
  businessPhoneNumberIsRequired: "Business phone number is required",
  businessPhoneNumberUsed: "Business phone number has already been used",
  businessType: "Business Type",
  businessTypeIsRequired: "Business type is required",
  businessTypePlaceholder: "Choose business type",
  codeReceiveCheck: "Didn’t receive the code?",
  confirmPassword: "Confirm Password",
  confirmPasswordIsRequired: "Confirm Password Field is required",
  contactName: "Contact Name",
  contactPersonName: "Contact Person Name",
  contactPhoneNumber: "Contact Phone Number",
  contactPhoneNumberIsRequired: "Contact phone number is required",
  continue: "Continue",
  eCommercePlatforms: "eCommerce Platforms",
  email: "Email",
  emailUsed: "Email is already been used",
  enterCodeMessage: "Enter the code from the E-mail we sent to",
  enterValidEmailAddress: "Please enter a valid email address",
  enterValidPhoneNumber: "Please enter a valid phone number",
  healthcarePharmaceutical: "Healthcare and Pharmaceutical Companies",
  login: "Login",
  logisticsTransportation: "Logistics and Transportation Companies",
  manufacturers: "Manufacturers",
  mustAcceptTermsAndPolicies: "You must accept the terms and policies",
  nameMinError: "The name must contain more than 3 letters",
  or: "or",
  password: "Password",
  passwordError8min: "Password must be at least 8 characters",
  passwordErrorNoNumber: "Password must contain at least one number",
  passwordErrorNoUppercase:
    "Password must contain at least one uppercase letter",
  passwordNoSpecialChar: "Password must contain at least one special character",
  passwordsMustMatch: "Passwords must match",
  personNameIsRequired: "Person Name is required",
  privacyPolice: "Privacy Police",
  register: "Register",
  registerFirstStep: "Step 1: Email",
  registerHeader: "Register your business",
  registerSecondStep: "Step 2: Business Info.",
  registerSubHeader: "Create your account using",
  resend: "RESEND",
  retailers: "Retailers",
  serviceBased: "Service-Based Businesses",
  termOfService: "platform’s Terms of Service",
  verification: "2FA Verification",
  verify: "Verify",
  wholesalersDistributors: "Wholesalers and Distributors",
};
