export const ORDER_INFORMATION_INPUT_FIELDS = (t, isExpedited = false) => {
  const allOrderInputs = [
    {
      id: 0,
      label: "receiverPhoneNumber",
      placeholder: "770000000",
      type: "tel",
    },
    {
      id: 1,
      label: "receiverFullName",
      placeholder: t("receiverFullName"),
      type: "text",
    },
    {
      id: 2,
      isOptional: true,
      label: "secondPhoneNumber",
      placeholder: "770000000",
      type: "tel",
    },
    {
      allowedStatuses: [1, 2],
      id: 3,
      label: "shipmentSchedule",
      placeholder: "DD/MM/YYYY",
      type: "schedule",
    },
    {
      allowedStatuses: [1, 2],
      id: 4,
      isSearchable: true,
      label: "pickupLocation",
      path: "location-mgmt/sender/getlocations?isActive=true",
      type: "select",
    },
    {
      allowedStatuses: [1, 2],
      id: 5,
      isSearchable: false,
      label: "dropOffLocation",
      path: "location-mgmt/sender/getreciverlocations?reciverId=",
      type: "text",
    },
    {
      allowedStatuses: [1, 2],
      id: 6,
      label: "contactPhoneNumber",
      placeholder: "770000000",
      type: "tel",
    },
    {
      id: 7,
      isSearchable: false,
      label: "contactName",
      type: "text",
    },
  ];

  if (isExpedited) allOrderInputs.splice(3, 1);

  return allOrderInputs;
};

export const SHIPMENT_DETAILS_INPUT_FIELDS = (t) => [
  {
    allowedStatuses: [1, 2],
    id: 7,
    isSearchable: false,
    label: "goodsCategory",
    path: "order-mgmt/ordercatagory",
    type: "select",
  },
  {
    allowedStatuses: [1, 2],
    id: 8,
    isSearchable: false,
    label: "preferredVehicleType",
    path: "order-mgmt/vehicletype",
    type: "select",
  },
  {
    allowedStatuses: [1, 2],
    id: 9,
    label: "quantity",
    placeholder: "1",
    type: "text",
  },
  {
    allowedStatuses: [1, 2],
    id: 10,
    isSearchable: false,
    label: "size",
    path: "order-mgmt/ordersize",
    type: "select",
  },
  {
    allowedStatuses: [1, 2],
    id: 12,
    label: "orderFees",
    placeholder: "13 JD",
    type: "text",
  },
  {
    allowedStatuses: [1, 2, 3],
    id: 13,
    label: "deliveryFees",
    placeholder: t("deliveryFeesPlaceHolder"),
    toolTipMessage: "Tooltip message",
    type: "checkBox",
  },
  {
    allowedStatuses: [1, 2, 4, 8],
    gridColumns: 6,
    id: 14,
    isOptional: true,
    label: "notes",
    placeholder: t("note"),
    type: "textArea",
  },
];

export const DELIVERY_FEES_OPTIONS = [
  {
    id: 0,
    label: "paid",
    toolTip: "paidOptionToolTip",
  },
  {
    id: 1,
    label: "notPaid",
    toolTip: "notPaidOptionToolTip",
  },
];

export const orderCreationIdsValues = [
  "pickUpLocationId",
  "dropOffLocationId",
  "dropOffLocation",
  "pickupLocation",
  "categoryId",
  "preferredVehicleType",
  "preferedVehicleTypeId",
  "quantity",
  "goodsCategory",
  "sizeId",
  "size",
];
